





































































import Embed from '@editorjs/embed'
import Marker from '@editorjs/marker'
import Quote from '@editorjs/quote'
import Raw from '@editorjs/raw'
import copy from 'copy-to-clipboard'
import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator'
import ContentRenderer from '~/components/app/content-renderer.vue'
import {NewBlogPost} from '~/components/data-class/data-class'
import ButtonLink from '~/components/editor-plugin/ButtonLink'
import Course from '~/components/editor-plugin/Course'
import Faq from '~/components/editor-plugin/Faq'
import Header from '~/components/editor-plugin/Header'
import ImageTool from '~/components/editor-plugin/ImageTool'
import List from '~/components/editor-plugin/List'
import Strikethrough from '~/components/editor-plugin/Strikethrough'
import Subscript from '~/components/editor-plugin/Subscript'
import Superscript from '~/components/editor-plugin/Superscript'
import Table from '~/components/editor-plugin/Table'
import TableOfContent from '~/components/editor-plugin/TableOfContent'
import Underline from '~/components/editor-plugin/Underline'
import Undo from '~/components/editor-plugin/Undo'
import {ImageUpload} from '~/utils/image-upload'
import {createRequest} from '~/utils/network-request'
import {ScriptLoader} from '~/utils/script-loader'


@Component({
    components: {
        ContentRenderer
    }
})
export default class ContentEditor extends Vue {
    @Ref() editor
    @Ref() preview

    @Prop({
        default: () => {
            return {}
        }
    }) value!: any


    //  META
    imageUploadHeader: {} = {}
    config: any = null
    //  UI DATA
    currTab: string = 'write'
    sbsEdit: boolean = true

    //  DATA
    outputData: {} = {}

    scheduledSave: any

    get localValue(): {} {
        return this.value
    }

    set localValue(value: {}) {
        this.input(this.value)
    }

    async mounted() {
        //  ScriptLoader for tinymce editor
        await ScriptLoader.load(`${process.env.VUE_APP_DOMAIN_ADMIN}/static/tinymce4.7.5/tinymce.min.js`)

        this.config = {
            logLevel: 'ERROR',
            tools: {
                paragraph: {
                    config: {
                        placeholder: 'Click here to start typing...'
                    }
                },
                header: {
                    class: Header,
                    config: {
                        placeholder: 'Enter a header',
                        levels: [2, 3, 4, 5, 6],
                        defaultLevel: 2,
                    },
                    inlineToolbar: true,
                },
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            async uploadByFile(file) {
                                let res = await Promise.all([
                                    ImageUpload.getImageWidthHeight(file),
                                    ImageUpload.upload('/blog/image', 'upload', file)
                                ])
                                return {
                                    success: 1,
                                    file: Object.assign({
                                        url: res[1].url,
                                        fileName: res[1].fileName,
                                    }, res[0])
                                }
                            },
                            async uploadByUrl(url) {
                                const file = await ImageUpload.urlToFile(url)
                                let res = await Promise.all([
                                    ImageUpload.getImageWidthHeight(file),
                                    ImageUpload.upload('/blog/image', 'upload', file)
                                ])
                                return {
                                    success: 1,
                                    file: Object.assign({
                                        url: res[1].url,
                                        fileName: res[1].fileName,
                                    }, res[0])
                                }
                            },
                        },
                    }
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                },
                embed: {
                    class: Embed,
                    config: {
                        services: {
                            youtube: true
                        }
                    }
                },
                table: Table,
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    config: {
                        quotePlaceholder: 'Enter a quote',
                        captionPlaceholder: 'Quote\'s author',
                    },
                },
                Marker: {
                    class: Marker
                },
                raw: Raw,
                underline: Underline,
                strikethrough: Strikethrough,
                subscript: Subscript,
                superscript: Superscript,
                tableOfContent: TableOfContent,
                course: Course,
                buttonLink: ButtonLink,
                faq: Faq
            },
            onReady: () => {
                const editor = this.editor.state.editor
                new Undo({editor})
            },
            onChange: (args) => {
                this.contentChangeHandler()
            },
            data: this.value
        }

        //  Hacky fix to solve editor.js not able to capture input event from tinymce
        window.addEventListener('tableUpdated', this.contentChangeHandler)


        if (this.value && this.value.blocks && this.value['blocks'].length) {
            this.outputData = this.value
        } else {
            this.outputData = {}
        }
    }

    beforeDestroy() {
        //  Hacky fix to solve editor.js not able to capture input event from tinymce
        window.removeEventListener('tableUpdated', this.contentChangeHandler)

        try {
            this.editor.state.editor.destroy()
            if (window && window['tinymce'])
                window['tinymce'].editors = []
        } catch (e) {
            console.log(e)
        }
    }

    contentChangeHandler() {
        try {
            this.save().then(() => {
                const container = document.getElementsByClassName('preview-container')[0]
                if (!!container) {
                    const links = Array.from(container.querySelectorAll('a'))
                    links.forEach(elem => {
                        elem.setAttribute('target', '_blank')
                    })
                }
            })
        } catch (e) {
            console.log('onChange:', e)
        }
    }

    save() {
        return this.editor.state.editor.save().then((outputData) => {
            this.outputData = outputData
            this.$emit('input', this.outputData)

            //  backup content
            if (this.scheduledSave) clearTimeout(this.scheduledSave)
            this.scheduledSave = setTimeout(this.saveBackup, 240000)

            return outputData
        }).catch((error) => {
            return false
        })
    }

    initRender(data) {
        return this.editor.state.editor.render(data).then(() => {

        }).catch((error) => {
            return false
        })
    }

    async copyToClipboard() {
        copy(JSON.stringify(this.outputData), {
            debug: false,
            message: 'Press #{key} to copy',
        })
        this.$message.success('Content Coped!')
    }

    async importFromClipboard() {
        const inputData = JSON.parse(await navigator.clipboard.readText())
        if (inputData.hasOwnProperty('time') && inputData.hasOwnProperty('blocks') && inputData.hasOwnProperty('version')) {
            this.outputData = inputData
            await this.initRender(this.outputData)
            this.$emit('input', this.outputData)
            this.$message.success('Content Imported!')
        } else {
            this.$message.error('Invalid Imported!')
        }
    }

    set(val) {
        this.outputData = val
        this.initRender(this.outputData)
    }

    @Emit()
    input(value: NewBlogPost) {
        //
    }

    async saveBackup() {
        await createRequest('info', 'post', {}, {
            color: 'info',
            title: `Blog Editor Backup - ${window.location.pathname}`,
            text: JSON.stringify(this.outputData)
        }).send()
            .then((res) => {
                return res.data
            }).catch(e => {
                return
            })
    }
}
