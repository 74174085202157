







































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {Course} from '~/components/data-class/data-class'
import {toInstanceForce} from '~/utils/Serializer'
import {SubjectList} from '@afterschool.dev/as-data-admin'
import {courseLink} from '~/utils/misc'

@Component({
    components: {}
})
export default class BlogCourseCard extends Vue {
    @Prop() courseId: string

    course: Course = new Course()

    showCard: boolean = false

    get tag() {
        const sub = SubjectList.allTag[this.course.subject]
        if (sub) return sub['cname']
        return ''
    }

    get discount() {
        return Math.floor((1 - this.course.price / this.course.original_price) * 100)
    }

    created() {
        if (this.courseId) {
            this.fetchCourse()
        }
    }

    fetchCourse() {
        if (this.courseId.length >= 6) {
            setTimeout(async () => {
                this.course = await createRequest(`/course/${this.courseId}`, 'get').send(false)
                    .then((res) => {
                        return toInstanceForce(new Course(), res.data)
                    }).catch(e => {
                        console.log(e)
                        return new Course()
                    })

                this.showCard = !!this.course
            }, 3000)
        }
    }

    courseLink(course) {
        return courseLink(course)
    }

    imageUrl(url, options = '') {
        return url ? url + options + '-rj' : null
    }

    @Watch('courseId')
    watchCourseId(newVal, oldVal) {
        if (newVal) {
            this.fetchCourse()
        }
    }
}
