














import {Component, Prop, Vue} from 'vue-property-decorator'
import BlogCourseCard from '~/components/blog/blog-course-card.vue'
import striptags from 'striptags'
import {faqTextToData} from '~/utils/misc'

@Component({
    components: {
        BlogCourseCard
    }
})
export default class ContentRenderer extends Vue {
    @Prop({default: () => []}) blocks: any[]
    @Prop({default: ''}) html: string
    @Prop({default: false}) previewMode: boolean
    @Prop(Boolean) noInit: boolean
    @Prop(Boolean) mobile: boolean


    //  save header list for sticky bar
    headerList: {}[] = []

    mounted() {
        if (!this.noInit) {
            if (this.blocks.length) {
                this.registerToCEvent()
            }

            //  init image without lazyload, defer load instead
            const imgDefer = document.getElementsByTagName('img')
            for (const item of imgDefer) {
                if (item.getAttribute('data-src'))
                    item.setAttribute('src', item.getAttribute('data-src') || '')
            }

            const container = document.getElementsByClassName('blog-content')[0]
            if (!!container) {
                const links = Array.from(container.querySelectorAll('.bk:not(.bk-tableOfContent) a'))
                links.forEach(elem => {
                    elem.setAttribute('target', '_blank')
                })
            }
        }
    }

    beforeDestroy() {
        if (!this.noInit) {
            //  remove click listener before destroy
            this.registerToCEvent(false)
        }
    }

    registerToCEvent(add: boolean = true) {
        const lists = document.getElementsByClassName('bk-tableOfContent')
        if (!!lists && lists.length > 0) {
            for (const list of lists) {
                const links = Array.from(list.querySelectorAll('a'))
                links.forEach(elem => {
                    elem[add ? 'addEventListener' : 'removeEventListener']('click', e => {
                        e.preventDefault()
                        const href = elem.getAttribute('href') || ''
                        if (!this.previewMode) {
                            if (!!href)
                                elem.scrollIntoView({behavior: 'smooth'})
                            window.location.hash = href
                        }
                    })
                })
            }
        }
    }

    transformTag(item) {
        switch (item.type) {
            case 'header':
                const tag = 'h' + item.data.level
                return tag
            default:
                return 'div'
        }
    }

    transformContent(item) {
        switch (item.type) {
            case 'header':
                return this.transformHeader(item.data)
            case 'paragraph':
                return item.data.text
            case 'list':
                return this.transformList(item.data.style, item.data.items)
            case 'quote':
                return this.transformQuote(item.data)
            case 'embed':
                return this.transformEmbed(item.data)
            case 'image':
                return this.transformImage(item.data)
            case 'tableOfContent':
                return this.transformTableOfContent()
            case 'raw':
                return this.transformRaw(item.data)
            case 'table':
                return this.transformTable(item.data)
            case 'buttonLink':
                return this.transformButtonLink(item.data)
            case 'faq':
                return this.transformFaq(item.data)
            default:
                return ''
        }
    }

    transformHeader(data) {
        const tag = 'h' + data.level
        if ([2, 3].includes(data.level)) {
            const id = !!data.anchor ? data.anchor : this.getCleanHeaderString(data.text)
            return `<span class="anchor" id="${id}"></span><span class="h${data.level + 2}">${data.text}</span>`
        } else {
            return `<span>${data.text}</span>`
        }
    }

    transformList(style, items) {
        const tag = style === 'ordered' ? 'ol' : 'ul'
        let html = items.reduce((html, item) => {
            return html + (typeof item === 'string' ? `<li>${item}</li>` : this.transformList(style, item))
        }, '')
        return `<${tag}>${html}</${tag}>`
    }

    transformQuote(data) {
        //  {'text': '教育的崩潰足以摧毀一個國家', 'caption': '曼德拉', 'alignment': 'left'}

        const caption = data.caption === '' ? '' : ` <div class="font-size-18 font-weight-normal mt-6">${data.caption}</div>`

        return `
            <blockquote class="${data.alignment === 'center' ? 'text-center' : ''}">
                <div class="h5">${data.text}</div>
                ${caption}
            </blockquote>
        `
    }

    transformEmbed(data) {
        const caption = data.caption === '' ? '' : ` <div class="p1 text-grey-700 mt-1">${data.caption}</div>`
        switch (data.service) {
            case 'youtube':
                return `
                    <div class="mb-6">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="${data.embed}" allowfullscreen></iframe>
                        </div>
                        ${caption}
                    </div>

                `
        }
    }

    transformImage(data) {
        /*
        {'type': 'image','data': {
        'file': {'url': 'https://lh3.googleusercontent.com/gm-FjX9jg04V4lrpW2li9Js0abOuKuSFryHwj5H6pZLIndmqcMom38BpSCaHWwRfo0FO02u-YZxLzmvP-7YB2AJ3BWDgWJSfyA=s0','fileName': 'sANo3G.png'}, 'caption': '', 'withBorder': false, 'stretched': false, 'withBackground': false
        }}
        */
        let resizedUrl = data.file.url
        // if (/googleusercontent/.test(data.file.url))
        //     resizedUrl = this.imageUrl(data.file.url, this.mobile ? '-w500' : '-w800')
        const caption = data.caption === '' ? '' : ` <div class="p2 text-grey-700 mt-1">${data.caption}</div>`
        const src = this.previewMode ? `src="${resizedUrl}"` : `data-src="${resizedUrl}"`
        const width = !!data.width && !!data.width.trim() ? `width="${data.width.trim()}"` : ''
        const height = !!data.height && !!data.height.trim() ? `height="${data.height.trim()}"` : ''
        const className: string[] = []
        if (data.stretched) className.push('w-100')
        if (data.withBackground) className.push('bk-image-with-bkg')
        return `
            <div class="mb-6">
                <img ${src} ${width} ${height} class="${className.join(' ')}" alt="${data.alt ? data.alt : data.caption}"/>
                ${caption}
            </div>
        `
    }

    transformTableOfContent() {
        const allHeader: any[] = this.blocks.filter(item => item['type'] === 'header' && [2, 3].includes(item.data.level))
        const headerList: any[] = []
        for (const item of allHeader) {
            if (item.data.level === 2) {
                headerList.push(item)
            } else {
                if (Object.prototype.toString.call(headerList[headerList.length - 1]) === '[object Array]') {
                    headerList[headerList.length - 1].push(item)
                } else {
                    headerList.push([item])
                }
            }
        }

        this.headerList = headerList

        const vm = this
        const arrayToLi = function (list) {
            let html = list.reduce((html, item) => {
                const isArray = Object.prototype.toString.call(item) === '[object Array]'

                let content = ''
                if (isArray) {
                    content = arrayToLi(item)
                } else {
                    item.data.striped = striptags(item.data.text)
                    item.data.hash = !!item.data.anchor ? item.data.anchor : vm.getCleanHeaderString(item.data.text)
                    content = `<li><a href="#${item.data.hash}">${item.data.striped}</a></li>`
                }
                return html + content
            }, '')
            return `<ul class="list-unstyled list-style-icon">${html}</ul>`
        }

        return `
            <div class="p1 mb-2">目錄</div>
            ${arrayToLi(headerList)}
        `
    }

    transformRaw(data) {
        //  {'type': 'raw', 'data': {'html': '...'}}
        return `
            <div class="w-100 overflow-hidden">
               ${data.html}
            </div>
        `
    }

    transformTable(data) {
        let tableClass: string[] = ['blog-table']
        let append = ''
        switch (data.style) {
            case 'SCROLL_LIST':
                tableClass.push('blog-table-sticky-scroll')
                append = '<div class="s1 text-grey-700 text-center mt-2">滑動查看更多</div>'
                break
        }

        return `
            <div class="overflow-auto">
                <div class="${tableClass.join(' ')}">
                    ${data.html}
                </div>
            </div>
            ${append}
        `
    }

    transformButtonLink(data) {
        return `
            <a href="${data.url}" target="_blank" class="btn btn-${data.outline ? 'outline-' : ''}${data.color} btn-block btn-xl">${data.text}</a>
        `
    }

    transformFaq(data) {
        return faqTextToData(data.text).reduce((html, item) => {
            return html + `
                <div class="bk-faq-item">
                    <div class="font-weight-semiBold">${item.q}</div>
                    <div>${item.a}</div>
                </div>
            `
        }, '')
    }

    getHeaderList() {
        return this.headerList
    }

    getCleanHeaderString(text) {
        let cleaned = text.replace(/[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g, '')
        cleaned = striptags(cleaned)
        cleaned = cleaned.replace(/[!@#$%^&*()_+-=,.\/<>?;':"\[\]\\{}|`~]/g, '')
        cleaned = cleaned.replace(/^\d*/, '')
        return cleaned.trim().replace(/\s/g, '-')
    }
 }
